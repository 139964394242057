<template>
    <header ref="siteHeader" :class="['header',{'header--not-top': isNotTop}]">
        <div class="container-fluid p-0">
            <div class="row">
                <div class="d-none d-xl-flex col-xl-auto ml-xl-auto align-self-center">
                    <nav class="nav">
                        <ul>
                            <li>
                                <a class="nav-link" href="#about">
                                    <span>ABOUT</span>
                                </a>
                            </li>
                            <li>
                                <a class="nav-link" href="#services">
                                    <span>SERVICES</span>
                                </a>
                            </li>
                            <li>
                                <a class="nav-link" href="#projects">
                                    <span>PROJECTS</span>
                                </a>
                            </li>
                            <li>
                                <a class="nav-link" href="#testimonials">
                                    <span>TESTIMONIALS</span>
                                </a>
                            </li>
                            <li>
                                <a class="nav-link" href="#contact">
                                    <span>CONTACT</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
                <!-- <div class="col-auto d-none d-xl-flex pl-0 pr-0 align-self-stretch">
                    <div class="strip">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 78.79 102">
                            <path fill="#094924" d="M0 102h64.3L16.03 0H0v102z"/>
                            <path fill="#aac838" d="m10 0 48 102h10.79L21.66 0H10z"/>
                            <path fill="#fff" d="m20.76 0 48 102h10.03V0H20.76z"/>
                        </svg>
                    </div>
                </div> -->
                <div class="col-12 col-xl-6 pl-xl-0">
                    <div class="contact-info-bar p-2 pt-xl-0 pb-xl-0">
                        <div class="row justify-content-center justify-content-xl-start align-items-center">
                            <div class="col-auto">
                                <div class="contact-info-bar__title">
                                    <span class="title title--2">
                                        <i class="fa-solid fa-phone"></i>
                                    </span>
                                    <p><strong>CALL TODAY FOR<br/> A FREE QUOTE:</strong></p>
                                </div>
                            </div>
                            <div class="col-auto">
                                <div class="contact-info">
                                    <span class="contact-info__name">JOSH:</span>
                                    <br/>
                                    <span><strong>07510 603058</strong></span>
                                </div>
                                <div class="contact-info">
                                    <span class="contact-info__name">JAKE:</span>
                                    <br/>
                                    <span><strong>07765 451085</strong></span>
                                </div>
                            </div>
                            <div class="col-auto">
                                <nav class="nav nav--social">
                                    <ul>
                                        <li class="nav__item">
                                            <a href="https://www.facebook.com/people/Baileys-Tree-Services/100087196034448/" target="_blank">
                                                <i class="fab fa-facebook"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>
<script>
import _debounce from 'lodash/debounce';

import LogoSvg from '../../../svg/logo.svg';

export default {
    data: () => ({
        isNotTop: false
    }),
    components: { LogoSvg },
    methods: {
        checkTop() {
            this.isNotTop = (window.scrollY || window.pageYOffset) > 0;
        },
        setHeaderHeightCssVar() {
            document.documentElement.style.setProperty('--header-height', `${this.$refs.siteHeader.getBoundingClientRect().bottom + 'px'}`); 
        }
    },
    mounted() {
        this.checkTop();
        this.setHeaderHeightCssVar();

        window.addEventListener('scroll', _debounce(() => {
            this.checkTop();
        },1));
    }    
}
</script>