import Vue from 'vue';

import SiteHeader from './components/SiteHeader';
import LogoSvg from '../../svg/logo.svg';
import AccordionItem from './components/AccordionItem';
const CognitoForm = () => import(/* webpackChunkName: 'cognito-form' */ './components/CognitoForm');

new Vue({
    el: '#app',
    components: {
        SiteHeader,
        LogoSvg,
        AccordionItem,
        CognitoForm
    }
})