<template>
    <div :class="['accordion-item',{'accordion-item--open': isOpen}]">
        <div class="accordion-item__top" @click="isOpen = !isOpen">
            <slot name="title"></slot>
        </div>
        <transition name="accordion"
            @before-enter="beforeEnter"
            @before-leave="beforeLeave"
            @enter="enter"
            @leave="leave"
        >
            <div v-show="isOpen" class="accordion-item__content">
                <slot name="content"></slot>
            </div>
        </transition>
    </div>
</template>
<script>
export default {
    data: () => ({
        isOpen: false
    }),
    methods: {
        beforeEnter: el => {
            el.style.height = '0';
        },
        enter: el => {
            el.style.height = el.scrollHeight + 'px';
        },
        beforeLeave: el => {
            el.style.height = el.scrollHeight + 'px';
        },
        leave: el => {
            el.style.height = '0';
        }
    }
}
</script>